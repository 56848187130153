g {
    font-family: 'Open Sans', Arial, sans-serif;
}

ul {
    padding-inline-start: 0;
}

li {
    list-style: none;
}
